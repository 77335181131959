import Vue from 'vue';
import _ from "lodash";
import {$http} from '@/utils/https';
import type {ActionContext} from "vuex";
import type {RootState} from "@/store";
import type {BasketProduct, Product, ProductPrice} from "@/types/shop";

const saveBasketProducts = (products: Array<BasketProduct>): void => {
  const parsed = JSON.stringify(products);
  localStorage.setItem('products', parsed);
};

const loadBasketProducts = (): Array<BasketProduct> => {
  if (localStorage.getItem('products')) {
    const products = JSON.parse(localStorage.getItem('products') || '[]');

    return _.filter(products);
  }

  return [];
}

/*
function sortByKey<T = any>(array: Array<T>, key: string): Array<T> {
    return array.sort((a, b) => {
        let x = a[key];
        let y = b[key];

        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
}
*/

// state
interface State {
  buyLoading: boolean;
  promoCode: string | null;
  discount: any;
  loadingPromoCode: boolean;
  productsWithDefaultPrices: Array<Product> | null;
  basket: Array<BasketProduct>;
  isShowBasketPopup: boolean;
  buyTogetherProducts: Array<Product>;
  clientProducts: Array<Product>;
  selectedBonuses: selectedBonuses
}

interface selectedBonuses {
  pay_bonus_amount: number
}

interface AddBasketPayload {
  select_count: number;
  product_price: ProductPrice;
  product: Product;
  discount?: number;
}

interface SetCountPayload {
  product: BasketProduct;
  select_count: number;
}

interface HandlePayload {
  basketProduct: BasketProduct;
  product: Product;
  productPrice: ProductPrice;
}

interface FindProductData {
  basketProduct: BasketProduct;
  product: Product;
  productPrice: ProductPrice;
}


const state: State = {
  buyLoading: false,
  promoCode: '',
  discount: null,
  loadingPromoCode: false,
  productsWithDefaultPrices: null,
  basket: loadBasketProducts(),
  isShowBasketPopup: false,
  buyTogetherProducts: [],
  clientProducts: [],
  selectedBonuses: {
    pay_bonus_amount: 0
  }
};

const hasProductTypeInBasket = (list: Array<BasketProduct>, type: string): boolean => {
  const counts = _.countBy(list, 'type');

  return (counts[type] || 0) > 0
}

// getters
const getters = {
  selectedBonuses: (state: State) => state.selectedBonuses,
  buyTogetherProducts: (state: State) => state.buyTogetherProducts,
  isShowBasketPopup: (state: State) => state.isShowBasketPopup,
  productsWithDefaultPrices: (state: State) => state.productsWithDefaultPrices,

  promoCode: (state: State) => state.promoCode,
  discount: (state: State) => state.discount,
  buyLoading: (state: State) => state.buyLoading,
  loadingPromoCode: (state: State) => state.loadingPromoCode,
  basket: (state: State) => state.basket || [],
  clientProducts: (state: State) => state.clientProducts || [],
};


// actions
const actions = {
  // CHECK_PROMO_CODE: async ({commit}: ActionContext<State, RootState) => {
  //   commit('CHECK_PROMO_CODE_LOADING', true);
  //   commit('RESET_DISCOUNT');
  //   try {
  //     const response = await $http.post(`v1/order/coupon`, {code: state.promoCode});
  //
  //     commit('SET_DISCOUNT', response.data.data.percent);
  //     return response.data;
  //   } catch (e) {
  //     throw e;
  //   } finally {
  //     commit('CHECK_PROMO_CODE_LOADING', false);
  //   }
  // },

  GET_BUY_TOGETHER_PRODUCT: async ({commit}: ActionContext<State, RootState>, payload: {
    ids: Array<number | string>
  }) => {
    // commit('CHECK_PROMO_CODE_LOADING', true);
    try {
      const response = await $http.get('v1/basket/products/buy-with-in-basket', {
        params: {
          products: payload.ids,
        }
      });
      commit('SET_BUY_TOGETHER_PRODUCT', response.data.data);
      // return response.data;
    } catch (e) {
      throw e;
    } finally {
      // commit('CHECK_PROMO_CODE_LOADING', false);
    }
  },
  GET_SYNC_BASKET: async ({commit}: ActionContext<State, RootState>, payload: { ids: Array<number | string> }) => {
    commit('CHECK_PROMO_CODE_LOADING', true);
    try {
      const response = await $http.get('v1/basket/products', {
        params: {
          ids: payload.ids
        }
      });
      commit('SET_BASKET_PRODUCTS', response.data.data);
      return response.data;
    } catch (e) {
      throw e;
    } finally {
      commit('CHECK_PROMO_CODE_LOADING', false);
    }
  },
  GET_DEFAULT_PRICE_BASKET: async ({commit}: ActionContext<State, RootState>, payload: {
    ids: Array<number | string>
  }) => {
    commit('CHECK_PROMO_CODE_LOADING', true);
    try {
      const response = await $http.get('v1/basket/products', {
        params: {
          ids: payload.ids,
          default_price: 1
        }
      });
      commit('SET_PRODUCTS_WITH_DEFAULT_PRICES', response.data.data);
      return response.data;
    } catch (e) {
      throw e;
    } finally {
      commit('CHECK_PROMO_CODE_LOADING', false);
    }
  },
  FETCH_PROMOCODE_INFO: async ({commit}: ActionContext<State, RootState>, payload: any) => {
    try {
      type TFetchResponse = { products: Array<any>, promocode: { value: string | null, activated: boolean } };

      const response = await $http.post<TFetchResponse>('/v1/checkout/sync-basket', payload);
      const basket: Array<BasketProduct> = []

      _(state.basket).each((basketProduct: BasketProduct): void => {
        const productData = _.find(response.data.products, ({id, productPrice: {value}}) => {
          return basketProduct.id === id && basketProduct.select_type.value === value
        })

        if (!productData) return;

        basket.push(makeBasketProduct({
          product: productData.product,
          product_price: productData.productPrice,
          select_count: basketProduct.select_count || 1,
          discount: productData.discount || 0
        }))
      })

      state.basket = basket;
      saveBasketProducts(state.basket)

      return response.data;
    } catch (e) {
      console.error(e);
    }
  },
  GET_CLIENT_PRICE_BASKET: async ({commit}: ActionContext<State, RootState>, payload: {
    ids: Array<number | string>
  }) => {
    try {
      const response = await $http.get('v1/basket/products', {
        params: {
          ids: payload.ids,
          client_price: 1
        }
      });
      commit('SET_PRODUCTS_WITH_CLIENT_PRICES', response.data.data);
      return response.data;
    } catch (e) {
      throw e;
    } finally {
    }
  },
};


const reloadBasketProducts = (state: State) => {
  const newBasket = loadBasketProducts();

  if (newBasket.length !== state.basket.length) {
    state.basket = newBasket
  }
}

const findProductIndex = (list: Array<BasketProduct>, basketProduct: BasketProduct): number => {
  return _.findIndex(list, ({id, select_type}) =>
    id === basketProduct.id && select_type.id === basketProduct.select_type.id
  );
}

const replaceProduct = (basketProduct: BasketProduct): Array<BasketProduct> => {
  const basketProducts = state.basket;

  const index = findProductIndex(basketProducts, basketProduct);
  if (index !== -1) {
    basketProducts[index] = basketProduct;
  } else {
    basketProducts.push(basketProduct)
  }

  return basketProducts;
}

const resolveProductCount = (basketProduct: BasketProduct, quantity: number | any): BasketProduct => {
  basketProduct.select_count = 0;

  const setQuantity = Math.min(
    Math.max(Number.parseFloat(quantity || '1'), 1),
    basketProduct.select_type?.quantity || 1
  );

  basketProduct.select_count = Math.ceil(setQuantity)

  return basketProduct;
}

const updateCountForProduct = (payload: SetCountPayload): Array<BasketProduct> => {
  const BasketProductsList = state.basket;

  const index = findProductIndex(BasketProductsList, payload.product);
  if (index !== -1) {
    BasketProductsList[index] = resolveProductCount(BasketProductsList[index], payload.select_count);
  }

  return BasketProductsList;
}

const makeBasketProduct = (payload: AddBasketPayload): BasketProduct => {
  return resolveProductCount({
    id: payload.product.id,
    title: payload.product.title,
    slug: payload.product.slug,
    image: payload.product.image,
    productPrices: payload.product.productPrices,
    type: payload.product.type,

    select_count: payload.select_count || 1,
    select_type: payload.product_price,
    client_price: undefined,
    client_with_code_price: undefined,
    discount: payload.discount,
  }, payload.select_count)
}

const updateBasketProduct = (basketProduct: BasketProduct, product: Product): BasketProduct => {
  return resolveProductCount({
    id: product.id,
    title: product.title,
    slug: product.slug,
    image: product.image,
    productPrices: product.productPrices,
    type: product.type,

    select_count: basketProduct.select_count,
    select_type: basketProduct.select_type,
    client_price: basketProduct.client_price,
    client_with_code_price: basketProduct.client_with_code_price,
    discount: basketProduct.discount,
  }, basketProduct.select_count)
}

const findProduct = (products: Array<Product>, basketProduct: BasketProduct): FindProductData | null => {
  const product = _.find(products, ({id}) => basketProduct.id === id)

  if (!product) {
    return null;
  }

  const productPrice = _.find(product.productPrices.data, ({value}) => basketProduct.select_type.value === value)

  if (!productPrice) {
    return null;
  }

  return {basketProduct, product, productPrice}
}

const refreshBasketProducts = (basketProducts: Array<BasketProduct>, products: Array<Product>, itemHandler: (payload: HandlePayload) => BasketProduct) => {
  const basket: Array<BasketProduct> = [];

  _.each(basketProducts, (basketProduct: BasketProduct): void => {
    const productData = findProduct(products, basketProduct)

    if (!productData) {
      basket.push(basketProduct)
      return
    }

    basket.push(updateBasketProduct(itemHandler(productData), productData.product))
  });

  return basket;
};

const addProductToBasket = (state: State, payload: AddBasketPayload): Array<BasketProduct> => {
  if (payload.product.hasDuplicate) {
    return state.basket
  }

  let basketProduct = state.basket.find(basketProduct => basketProduct.id === payload.product.id
    && basketProduct.select_type.value === payload.product_price.value);

  if (basketProduct) {
    basketProduct = resolveProductCount(
      basketProduct,
      basketProduct.select_count + payload.select_count
    );
  } else {
    basketProduct = makeBasketProduct(payload)
  }

  return replaceProduct(basketProduct);
}

setInterval(() => {
  reloadBasketProducts(state)
}, 10000);

// mutations
const mutations = {
  ADD_ITEM_TO_BASKET(state: State, payload: AddBasketPayload) {
    state.basket = addProductToBasket(state, payload)

    saveBasketProducts(state.basket);
  },
  ADD_WEBINAR_ITEM_TO_BASKET(state: State, payload: AddBasketPayload) {
    state.basket = addProductToBasket(state, payload)

    saveBasketProducts(state.basket);
  },
  REMOVE_ITEM_FROM_BASKET(state: State, basketProduct: BasketProduct) {
    Vue.delete(state.basket, findProductIndex(state.basket, basketProduct))

    saveBasketProducts(state.basket);
  },
  RESET_BASKET(state: State) {
    state.basket = [];

    saveBasketProducts(state.basket)
  },
  CHECK_PROMO_CODE_LOADING(state: State, status: boolean) {
    state.loadingPromoCode = status;
  },
  INCREMENT_BASKET_LIST_COUNT(state: State, basketProduct: BasketProduct) {
    state.basket = updateCountForProduct({
      select_count: basketProduct.select_count + 1,
      product: basketProduct
    })

    saveBasketProducts(state.basket);
  },
  SET_BASKET_LIST_COUNT(state: State, countPayload: SetCountPayload) {
    state.basket = updateCountForProduct(countPayload)

    saveBasketProducts(state.basket);
  },
  DECREMENT_BASKET_LIST_COUNT(state: State, basketProduct: BasketProduct) {
    state.basket = updateCountForProduct({
      select_count: basketProduct.select_count - 1,
      product: basketProduct
    })

    saveBasketProducts(state.basket);
  },
  SET_PROMO_CODE_IN_BASKET(state: State, code: string | null) {
    state.promoCode = code;
  },
  SET_DISCOUNT(state: State, discount: any) {
    state.discount = discount;
  },
  RESET_DISCOUNT(state: State) {
    state.discount = null;
  },
  SET_BASKET_PRODUCTS(state: State, products: Array<Product>) {
    const basket: Array<BasketProduct> = []

    _(state.basket).each((basketProduct: BasketProduct): void => {
      const productData = findProduct(products, basketProduct)

      if (!productData) {
        return;
      }

      basket.push(makeBasketProduct({
        product: productData.product,
        product_price: productData.productPrice,
        select_count: basketProduct.select_count || 1
      }))
    })

    state.basket = basket;
    saveBasketProducts(state.basket)
  },
  SET_PRODUCTS_WITH_DEFAULT_PRICES(state: State, products: Array<Product>) {
    state.productsWithDefaultPrices = products

    const itemHandler = ({basketProduct, productPrice}: HandlePayload): BasketProduct => {
      basketProduct.client_price = productPrice
      return basketProduct
    }

    state.basket = refreshBasketProducts(state.basket, products, itemHandler)
    saveBasketProducts(state.basket)
  },
  SET_PRODUCTS_WITH_CLIENT_PRICES(state: State, products: Array<Product>) {
    state.productsWithDefaultPrices = products

    const itemHandler = ({basketProduct, productPrice}: HandlePayload): BasketProduct => {
      basketProduct.client_with_code_price = productPrice

      return basketProduct
    }

    state.basket = refreshBasketProducts(state.basket, products, itemHandler)
    saveBasketProducts(state.basket)
  },
  SHOW_BASKET_POPUP(state: State, status: boolean) {
    state.isShowBasketPopup = status
  },
  SET_BUY_TOGETHER_PRODUCT(state: State, data: Array<Product>) {
    state.buyTogetherProducts = data;
  },
  CLEAR_BYTOGETHER_PRODUCTS(state: State) {
    state.buyTogetherProducts = []
  },
  SET_USER_BONUSES(state: State, data: selectedBonuses) {
    state.selectedBonuses = data
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
